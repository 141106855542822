.projects-list{
    //content
    &__content{
        min-width: 600px;
    }

    &__table-head{
        background: rgb(255,255,255);
        color: #385980;
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid rgb(196, 195, 195);
        position: sticky;
        top: 0;
        gap: 1rem;
        height: 60px;
        z-index: 2;

        & > h2 {
            flex-basis: 10%;
        }

        & h3 {
            font-size: 15px;
            font-weight: 600;
            flex-basis: 20%;
            text-align: left;
            margin-top: 1rem;
            display: flex;
            align-items: flex-end;
            gap: 0.4rem;
        }

        & h3:first-child(){
            margin-left: 1rem;
        }

        & h3:nth-child(n+3){
            text-align: center;
            justify-content: center;
        }

        & h3:not(:nth-child(n+3)){
            &:hover{
                cursor: pointer;
            }
        }
    }

    &__table-body{
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 1rem;

        &:nth-child(2n+1){
            background: $widget-alternate-row-highlight;
        }

        & li {
            font-family: "ProximaNovaRegular";
            font-size: 15px;
            color: #6D747E;
            text-align: left;
            flex-basis: 20%;
            margin: 0.25rem 0 0.25rem 0;            
            height: 32px;
            line-height: 1.1;
            display: flex;
            align-items: center;
        }

        & li:first-child(){
            margin-left: 1rem;
        }

        & li:nth-child(n+3){
            text-align: center;
            justify-content: center;
        }

        & li:not(:nth-child(n+3)){
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        & a{
            text-decoration: none;
        }

        & span{
            display: inline-block;
            padding: 0.5rem 0.5rem;
            color: white;
            font-family: "ProximaNovaBold";
            font-size: 0.8rem;
            border-radius: 4px;
            width: 125px;
            height: 30px;

            background: #7BB8FF;

            &:hover{
            cursor: pointer;
            background-color: #2F8FFF;
            }
        }
    }
}
