.projects {
  .check-holder {
    flex: 0 0 1.5em;
    height: 1.5em;
    width: 1.5em;
    float: right;
    position: relative;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkbox {
      height: 25px;
      width: 25px;
    }

    .checkmark {
      font-size: 13px;
    }
  }

  .checkbox {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    color: #ffffff;
    background-color: #ffffff;
    border: 1px solid #979797;
    border-radius: 2px;
  }

  .checkbox:hover {
    background-color: rgba(151, 151, 151, 0.25);
  }

  .checked {
    background-color: #7bb8ff;
    border: none;
  }

  .checked:hover {
    background-color: #7bb8ff;
  }
}
.project-list {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.project {
  border: 1px solid $brookseyBorder;
  border-radius: $border-radius;
  min-height: 12em;
  flex-basis: 30%;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  min-width: 250px;

  .project-main {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  &:hover {
    cursor: pointer;
    background-color: darken($athensGray, .03%);
  }

  h3 {
    color: $chambray;
    margin: 0;
    font-family: $font-family-regular;
    font-weight: normal;
    word-break: normal;
  }

  h4 {
    color: $chambray;
    margin: 0;
    font-family: $font-family-regular;
    font-weight: bold;
  }

  h6 {
    margin: 0;
    color: $chambray;
    font-weight: normal;
  }

  .simple-box {
    border: 1px solid $baliHai;
    border-radius: 3px;
    padding: 0.25em 0.7em;
  }

  .flex-row {
    justify-content: space-between;
    margin-top: auto;

    .flex-item {
      padding: 0;
      flex-basis: 70%;
      color: $baliHai;
      font-size: 0.8em;

      i {
        font-style: normal;
        margin-right: 0.5em;
        margin-left: 0.5rem;
      }
    }
  }
  .project-header {
    display: flex;
    justify-content: space-between;
  }
}

.post-storm-controls {
  display: flex;
  justify-content: space-between;

  button {
    margin-left: 1em;
  }
  .select-all {
    margin-bottom: 1em;
    margin-right: 5em;
    display: flex;
    .check-label {
      width: 100%;
      color: #7d92ac;
      font-weight: bold;
      font-size: 14px;
      margin-left: 1em;
      margin-top: 0.2em;
    }
  }
  .storm-buttons {
    margin-bottom: 2em;
  }
}

.error-msg{
  color: $chambray;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.error-button{
  background-color: $malibu;
  color: #fff;
  margin: 2rem;
}
@include breakpoint("up-to-tablet") {
  .hide-project-create {
    display: none;
  }
  .project-search {
    position: relative;
    width: calc(100% - 3em) !important;
  }
  .project-list {
    .project {
      flex-basis: 100%;
      margin-right: 0;
    }
  }
}


// media query

@media (max-width: $breakpoint-md) {
  .project-resource-index-wrapper > article > header > input{ 
    width: 100%;
    margin-bottom: 1rem;
  }
}