.react-tabs__tab-list {
  border-bottom: none;
  margin: 1rem 0 1rem 0;
}

.react-tabs__tab {
  color: #ccc;
  font-weight: bold;
  border: none;
  border-bottom: $border-radius solid $mercury;
  font-size: $font-size-base * 0.8;
  text-decoration: none;
  
}

.react-tabs__tab--selected {
  color: $rhino;
  font-weight: bold;
  border: none;
  border-top: solid $rhino;
  box-shadow: 0px -2px 3px grey;
  border-radius: 0;
}

.react-tabs__tab:focus {
  box-shadow: none ;
  border-color: inherit;
  outline: none;
  box-shadow: 0px -2px 3px #B2B2B2;
  border-radius: 0;
  &::after {
    display: none;
  }

  &:active {
    background-color: $navtab-active-color-gray;
    box-shadow: none;
  }

}

@media screen and (max-width: $breakpoint-md) {
  .react-tabs__tab-list {
    height: 2.5rem;
    overflow-x: auto;
    white-space: nowrap;
    
  }
}