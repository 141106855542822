%nav-text {
  font-family: $font-family-bold;
  font-size: $font-size-base * 0.9;
}

.side-nav {
  @extend %nav-text;
  padding: 0;
  margin: 0;

  li {
    margin: 0;
    list-style: none;

    a {
      color: $white;
      text-decoration: none;
      display: block;
      height: 3.5em;
      padding: 1.75em;
      display: flex;
      align-items: center;

      &:hover,
      &.selected {
        background-color: $bigStone;
      }

      span {
        margin: 0 0.75em;
      }
    }
  }
}

.sideNaveShift{
  @extend .side-nav;
  display: flex;
  flex-direction: column;
  > li > a{
    font-size: 1.2em;
  }
}

.top-nav {
  padding: 1rem;
  margin: 0 0 1rem;
  width: 100%;
  border-bottom: $border;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .inline-nav {
    // display: inline-flex;
    align-items: center;
    @extend %text-overflow;
    flex: 1 0 100%;
  }

  @include breakpoint("md") {
    flex-wrap: nowrap;

    .inline-nav {
      flex: 0 1 auto;
    }
  }

  a,
  h4 {
    color: $chambray;
    font-family: "ProximaNovaBold";
    text-decoration: none;
    font-size: inherit;
    white-space: nowrap;
  }

  a:not(:first-of-type) {
    white-space: nowrap;
    @extend %text-overflow;
    font-family: "ProximaNovaRegular";
  }
  svg {
    color: $chambray;
  }
}

.user-profile-menu {
  width: 7rem;
}

.notification-menu {
  width: 15rem;
  max-height: 75vh;

  .header {
    padding: 0.5rem;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    h3,
    a {
      margin: 0;
      @extend %nav-text;
    }
  }

  .nav-list {
    @extend %nav-text;
    padding: 0;
    margin: 0;
    list-style: none;

    height: 30vh;
    min-height: 200px;
    overflow: auto;
  }

  .nav-list-item {
    border-top: $border;
    padding: 0.5rem;
    font-size: $font-size-base * 0.8;
    color: $rhino;
    background-color: $grayedBackground-light;

    &.isOpened {
      background-color: $white;

      .title {
        color: $rhino;
      }
    }
  }

  .title {
    color: $bitterSweet;
  }

  .notification-detail {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    font-family: $font-family-regular;
  }
}

.nav-icon {
  height: 1.75rem;
  font-size: 1.5rem;
  width: auto;
  color: $white;

  &:hover {
    color: $btn-hover-color-gray;
  }
}

.hamburger-nav-bar {
  display: none;
  justify-content: space-between;
  align-items: center;
  background-color: $chambray;
  color: #fff;
  padding: 0 1rem;
  width: 100%;
  height: 3rem;

  a {
    color: #ffffff;
  }

  .notification-bell {
    display: none;
  }

  .pad-right {
    padding-right: 0.5em;
  }
}

.nav-menu {
  position: fixed;
  margin: 0;
  height: 100%;
  background: #284260;
  padding: 0;
  top: 0;
  left: -500px;
  width: 32%;
  z-index: 250;
  transition: left 0.3s ease-out;

  a {
    color: #ffffff;
  }

  header {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0.75rem;
  }

  &.open {
    left: 0;
  }
}

.menu-backdrop {
  .modal-main {
    display: none;
  }
}

.notification-bell {
  position: relative;
  svg {
    color: $baliHai;
    &.active {
      color: $chambray;
    }
  }

  span {
    position: absolute;
    top: 0;
    left: 7px;
    color: $white;
    background-color: $bitterSweet;
    font-size: 7px;
    height: 10px;
    min-width: 10px;
    border-radius: 90px;
    text-align: center;
  }
}

.hamburger-nav-bar .notification-bell {
  svg {
    color: $white;
    &.active {
      color: $white;
    }
  }

  span {
    top: -1.4em;
    left: 1.2em;
    font-size: 10px;
    height: 15px;
    min-width: 15px;
  }
}

.division-selector {
  margin-right: 1.25em;
}

.sidebarToggleButton{
  background-color: #fff;
  border: 1px solid #284260;
  border-radius: 50%;
  float: right;
  margin: -3em -0.9em 0 0;
  height: 2em;
  width: 2em;
  padding: 0;
  position: relative;
  &:hover, &:focus{
    background-color: #fff;
  }
}
.sidebarToggleIcon{
  width: 1em !important;
  height: 1em;
  margin-top: 0.3em;
  > path{
    fill: #284260 !important
  }
}
@include breakpoint("up-to-desktop") {
  .hamburger-nav-bar {
    display: flex;
  }
  .modal-home-link {
    margin-left: 30%;
  }
  .top-nav {
    .dashboard {
      display: none;
    }
    .user-details {
      display: none;
    }
  }
  .hamburger-nav-bar .notification-bell {
    display: inline;
  }
}

@include breakpoint("desktop-and-up") {
  .nav-menu {
    display: none;
  }
}

@include breakpoint("up-to-tablet") {
  .nav-menu {
    width: 230px;
  }
  .modal-home-link {
    margin-left: 50px;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .top-nav {
    &.inspection .breadcrumbs {
      a:not(:nth-last-of-type(2)),
      svg:not(:nth-last-of-type(2)) {
        display: none;
      }

      svg:nth-last-of-type(2) {
        transform: rotate(180deg);
      }
    }
    .user-details {
      display: none;
    }
  }
}
