table.changelog-table.list-table {
  border-spacing: 0;
  border-collapse: separate;

  th {
    font-size: 1rem;
  }

  td {
    padding: 0.5rem;
  }

  tr.break td {
    border: none;
    padding: 0.5rem;
  }

  .group-header-row td {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-family: $font-family-bold;
  }
  .log-row {
    %td-basis {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:not(.last) td {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      @extend %td-basis;
    }
    td {
      @extend %td-basis;
    }
  }
}

table.changelog-table ~ table.changelog-table {
  margin: 1rem 0 2rem;
}
