.report-page {
    color: white;
    padding: 1rem 3rem;
    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    height: 100%;

    &__logo {
        width: 175px;
    }

    &__title {
        color: white;
        font-weight: bold;
        font-size: 18px;
        padding-bottom: .5rem;
    }

    &__synopsis {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__manual-download {
        text-align: center;
        padding: 0 3rem;
    }
}