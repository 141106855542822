.layout--narrow {
  width: 95%;
  padding: 1em;
  background: linear-gradient(132.05deg, #0e3353 0%, #385980 100%);
  box-shadow: 4px 4px 60px 0 rgba(0, 0, 0, 0.4);

  .app-logo {
    margin: 0 0 50px 0;
  }

  .error,
  .success {
    padding: 0 0 1em 0;
  }

  @include breakpoint("md") {
    width: 800px;
    padding: 3em 3.2em 0 21.5em;
    height: 490px;
    background: url("./images/login-bg.png") left center no-repeat;
    margin: 0 auto;
  }
}
