.inspection-listing {
  line-height: 17px;
  flex-wrap: wrap;

  .insp-check-holder {
    position: relative;
    float: right;
    height: 1.5em;
    width: 1.5em;
    margin: 0;

    &.disabled:hover {
      cursor: not-allowed;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkbox {
      height: 25px;
      width: 25px;
    }
    .checkmark {
      font-size: 13px;
    }

    .checkbox {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      color: #ffffff;
      background-color: #ffffff;
      border: 1px solid #979797;
      border-radius: 2px;
    }
    .checkbox:hover {
      background-color: rgba(151, 151, 151, 0.25);
    }

    .checked {
      background-color: #7bb8ff;
      border: none;
    }
    .checked:hover {
      background-color: #7bb8ff;
    }
  }
  .inspection {
    font-size: 14px;
    color: $chambray;
    border: 1px solid $brookseyBorder;
    border-radius: $border-radius;
    position: relative;
    padding: 1em;
    margin-bottom: 1rem;
    width: 49%;

    .questions,
    .actions {
      padding: 0.5em 0 0 0;
      margin: 0;

      &:first-of-type {
        padding-top: 1em;
      }
    }

    h2 {
      font-size: $font-size-base * 1.1;
      font-family: $font-family-regular;
      color: $chambray;
      margin: 0;
    }

    .inspection-details {
      text-decoration: none;
      color: $chambray;

      &__header{
        display: flex;
        justify-content: space-between;
        padding-right: 5px;
        grid-gap: 10px;
      }
    }

    .actions {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;

      div {
        display: inline-flex;
        align-items: center;
        &:first-of-type {
          padding-right: 0.5rem;
        }
      }

      .icon-only-button {
        font-size: 12px;
        margin: 0 0 0 0.5em;
        padding: 0 0.25rem;
        border: 1px solid $baliHai;
        border-radius: $border-radius;
        display: inline-block;
        text-align: center;

        &.inCompleteCA {
          border: 1px solid $bitterSweet;
        }
        &.inCompleteMI {
          border: 1px solid $buttercup;
        }
      }
    }
  }

  .flex-row {
    flex-flow: row wrap;
  }
}

//media Queries ------------------------------------------------
@include breakpoint("up-to-tablet") {
  .flex-item {
    flex-basis: 100%;
    margin-right: 0;
  }
}

@media (max-width: 800px){
  .inspection-details{
    &__header{
      flex-direction: column;
    }
  }
}

.inspection-pagesize{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #337ab7;
}

.pagesize-item{
  height: 43px;
  margin-left: 20px;
  border: 1px solid #7d92ac;
  outline: 0;
  background-color: transparent;
  border-radius: 4px;
}