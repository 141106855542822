.document-group {
  list-style: none;
  margin: 0;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  &__expand-action {
    margin: 0 auto;
    margin-top: 0.5rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(56, 98, 128, 0.29);
  }

  &__header-right {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;

    & svg{
      &:hover{
        cursor: pointer;
        color: rgba(56,89,128,0.8);
      }
    }
  }

  &__destination-modal-dropdown{
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title {
    margin: 1rem 0 0;
    font-size: 18px;
    display: inline-block;

    &--empty-state {
      text-align: center;
      color: $chambray;
      font-size: 14px;
    }
  }

  &__items {
    flex: 1 1 auto;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }

  &__status {
    &--empty {
      font-size: 14px;
      text-align: center;
      color: $chambray;
    }
  }

  &__list-header {
    margin: 1rem;
    margin-top: 1.5rem;
    color: $chambray;
  }

  &__list-th {
    display: table-cell;
    padding: 0 1rem;
    font-weight: normal;
    font-size: 14px;
    font-family: "ProximaNovaBold";

    &--hide-on-mobile {
      @media only screen and (max-width: 550px) {
        display: none;
      }
    }
  }
}
