.project-header {
  %flex-row {
    display: flex;
    flex-flow: row wrap;
    line-height: 1.5rem;
  }



  .project-info-column {
    display: flex;
    flex-flow: column nowrap;
  }

  .project-info-date {
    padding-top: 1.2em;
  }

  // project name
  h2 {
    font-size: 1.5em;
    font-family: ProximaNovaRegular;
    line-height: 1.75rem;
    margin-bottom: 0.5em;
  }

  // address
  h3 {
    font-size: 1em;
    font-family: ProximaNovaThin;
    margin-bottom: 0.5em;
  }

  // client name
  h5 {
    font-size: 1em;
    font-family: ProximaNovaThin;
    margin-bottom: 0.1em;
    line-height: 1.75rem;
  }
}

.header-icon {
  vertical-align: baseline;
  font-size: 0.75rem;
  width: auto;
  min-width: 1em;
  margin-right: 0.5em;
}

.print-inspections-modal {
  .modal-main {
    overflow: auto;
  }
}

.project-title-seprator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}