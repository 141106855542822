// Base html element styles along with global and utility classes

* {
  box-sizing: border-box;
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  background-color: $body-bg;
  font-weight: 400;
  line-height: $line-height-base;
  text-align: left;
  background: url('images/background_image.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

h2 {
  color: $chambray;
  font-family: $font-family-regular;
  font-size: $font-size-base * 1.9;
  font-weight: normal;
  margin: 0 0 0.1em 0;
}

h3 {
  color: $chambray;
  font-family: $font-family-regular;
  font-size: $font-size-base * 1.2;
  font-weight: normal;
  margin: 0 0 0.5em;
}

h4 {
  font-family: $font-family-regular;
  margin: 0;
  font-size: $font-size-base * 0.9;
}

h5 {
  font-family: $font-family-regular;
  margin: 0;
  color: $baliHai;
}

a {
  color: $link-color;
  font-size: $font-size-base * 0.9;
}

b,
strong {
  font-family: $font-family-bold;
}

.no-underline {
  text-decoration: none;
}

.no-underline:hover {
  color: $password-reset-hover-red;
}

.error {
  color: $bitterSweet;
  padding: 1.5em 0;

  .fa {
    padding-right: 0.5em;
  }
}

.success {
  color: $mantis;
  padding: 1.5em 0;

  .fa {
    padding-right: 0.5em;
  }
}

.red-light {
  color: $bitterSweet;
}

.yellow-light {
  color: $buttercup;
}

.green-light {
  color: $mantis;
}

.clear {
  clear: both;
}

.center {
  text-align: center;
}

.pointer:hover {
  cursor: pointer;
}

// truncate multi-line text
.clamp-lines-4 {
  overflow: hidden;
  position: relative;
  line-height: 0.8rem * 1.5;
  max-height: 0.8rem * 1.5 * 4;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;

  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
}

// custom button colors
.button-primary {
  background-color: $malibu;
  border-radius: $border-radius;
  color: white;
  font-weight: bold;
}

.button-outline-dark {
  background-color: transparent;
  border: 1px solid $baliHai;
  border-radius: $border-radius;
  color: $baliHai;
}

.button-outline-blue {
  background-color: transparent;
  border: 1px solid $malibu;
  border-radius: $border-radius;
  color: $malibu;
  font-weight: bold;
}

.button-transparent {
  &,
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    box-shadow: none;
  }
}

.button-disabled {
  pointer-events: none;
}

button.default {
  background: $white;
}

button {
  padding: 0.7em 1em;
  // margin-bottom: 1em;
}

.flex-pull-right {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  &.last-child {
    margin-right: 1em;
  }
}

// text alignment styles
.text-center {
  text-align: center;
}

// spacing styles
.ml-auto {
  margin-left: auto;
}

.invisible {
  visibility: hidden;
}

.hidden {
  display: none !important;
}

.pseudo-hidden {
  height: 0 !important;
  width: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  left: -3000px;
}

.right-popper .react-datepicker__triangle {
  // defaults to left 50px
  right: 50px;
  left: unset;
}

.pad-left {
  margin-left: 0.5em;
}

.pad-right {
  margin-right: 0.5em;
}

.pad-none,
div button.pad-none {
  margin: 0;
  padding: 0;
}

input.width-auto,
.width-auto {
  width: auto;
}

// pill-shaped element for findings icon
.tag {
  border-radius: $border-radius;
  padding: 0.25em 0.75em;
  font-size: $font-size-base * 0.8;
  font-weight: bold;
  color: white;

  &.red-light {
    background-color: $bitterSweet;
  }

  &.yellow-light {
    background-color: $buttercup;
  }

  &.green-light {
    background-color: $mantis;
  }
}

.flex-inline,
button.flex-inline {
  display: inline-flex;
}

// truncate text to one line with ellipsis
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-icon {
  width: 50px;
  height: auto;
  max-width: 100%;
}

.tool-tip {
  max-width: 18.75rem;
}

@include breakpoint('up-to-desktop') {
  .top-nav ~ section {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@include breakpoint('up-to-tablet') {
  .mobile-hidden {
    display: none;
  }
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}



