.project-form-modal {
  .modal-wide {
    padding-top: 1rem;
  }

  .project-form {
    display: flex;
    flex-wrap: wrap;
  }

  .project-form .container {
    flex: 1 1 50%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
