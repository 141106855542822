.electronic-signature-control {
  width: 100%;
  margin-top: 3em;

  .signature-form {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1 1 auto;
    padding: 0.5em 1em;
    border-top: 1px solid rgba(151, 151, 151, 0.25);
    color: $rhino;

    .modal-icon {
      :hover {
        cursor: pointer;
      }
    }

    input {
      width: auto;
      margin-right: 1em;
    }
  }

  @include breakpoint("md") {
    width: 48%;
  }
}
