.new-inspection {
  margin-bottom: .5rem;
  height: 2.5rem;
}

.insp-save-continue-btn{
  order:2;
  margin-left: 0.5rem;
  background-color: #7bb8ff !important;
  color: #fff !important;
  border: 0 !important;
  font-family: "ProximaNovaBold" !important;
  font-size: 0.9rem !important;
  border-radius: 4px !important;
  padding: 0.7em 1em !important;
}

.insp-save-continue-btn:hover{
  background-color: #2f8fff !important;
}