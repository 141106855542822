// users index
.pagination {
  justify-content: center;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  min-width: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  font-size: 25px;
}

ul.pagination li a {
  text-decoration: none;
  color: #337ab7;
  font-size: 20px;
}

ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: #337ab7;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: blue;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.users-index {
  .list-table td:last-child {
    text-align: left;
    white-space: nowrap;
  }
}

.users-index > section{
  padding: 0 1rem;
}

.users-index-user-status {
  span{
    width: 7ch;
    display: inline-block; 
  }

  button {
    margin-left: 0.25rem;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  svg {
    width: 1em;
    height: 1em;
  }
}

// edit user
.edit-user-form {
  .pure-u {
    padding: 0 0.5em;
  }
}

// delete user
.delete-user {
  position: relative;
  float: left;
}
