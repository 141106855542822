.layout--sidebar {
  max-width: $layout-width;
  .sidebar {
    color: $white;
    background-color: $body-bg;
    min-height: 100vh;
    width: 15em;
    display: inline-block;
    vertical-align: top;
    transition: 0.5s;
    
    .logo {
      margin: 4em 0em 1em 1em;
      height: auto;
      width: 80%;
      max-width: 8em;
    }

    .nav {
      display: none;
    }
  }

  .content {
    padding: 0 23px;
    background-color: $white;
    min-height: 100vh;
    display: inline-block;
    width: $content-width;
    transition: 0.5s;
    vertical-align: top;
  }

  .sidebarShift{
    @extend .sidebar;
    width: 5em;
    transition: 0.5s;
  }

  .contentShift{
    @extend .content;
    width: calc(100% - 5em);
    transition: 0.5s;
  }

  @include breakpoint('up-to-desktop') {
    .content {
      width: 100%;
      padding: 0;
    }

    .sidebar {
      display: none;
    }
  }
}