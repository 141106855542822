.form-text {
  display: block;
  margin: 0;
  padding: 0;
  font-family: "ProximaNovaRegular";
  line-height: 1rem;
  font-size: 0.8rem;
}

.form-text-info {
  color: mix(#385980, white, 66);
}

.form-text-error {
  color: rgb(187, 28, 28) !important;
}
