@font-face {
  font-family: ProximaNovaBold;
  src: local('MyFont'), url('./fonts/Proxima Nova Alt Bold.otf') format('opentype');
}

@font-face {
  font-family: ProximaNovaRegular;
  src: local('MyFont'), url('./fonts/Proxima Nova Alt Regular.otf') format('opentype');
}

@font-face {
  font-family: ProximaNovaThin;
  src: local('MyFont'), url('./fonts/Proxima Nova Alt Thin.otf') format('opentype');
}
